import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles, Button } from "@material-ui/core";
import { Menu, MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomModal } from "../../../components";

const customStyle = makeStyles({
  camera: {
    display: "flex",
    flexDirection: "column",
  },
  cameraContainer: {
    gap: "10px",
    display: "flex",
    minWidth: "650px",
  },
  addedItemContainer: {
    gap: "10px",
    margin: "15px 0px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "115px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  formInput: {
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "18px",
  },
});

const UpdateModal = (props) => {
  const {
    isModalOpen,
    isLoading,
    onClose,
    currentImages,
    menuItems,
    addedItems,
    setAddedItems,
    handleAddItem,
    handleDeleteItem,
    onChangeValue,
    handleSubmit,
    validationErrors,
    submitLoading,
    images,
    currentImagesfirst,
    handleNothing
  } = props;
  const classes = customStyle();
  const [anchorEls, setAnchorEls] = useState([]);
  const [one, setOne] = useState([]);

  // Initialize anchorEls when menuItems change
  useEffect(() => {
    setAnchorEls(Array.from({ length: addedItems.length }, () => null));
  }, [menuItems, addedItems.length]);

  const handleMenuOpen = (event, index) => {
    const newAnchorEls = Array.isArray(anchorEls) ? [...anchorEls] : [];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleMenuClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const onChangeMenuItem = (menuItem, id) => {
    setAddedItems((prevItems) => {
      const itemToUpdate = prevItems.find((item) => item.id === id);
      if (itemToUpdate) {
        itemToUpdate.selectedItem = menuItem;
      }
      return prevItems;
    });

    setAnchorEls(null);
  };

  const isValidInput = (id) =>
    validationErrors.some((error) => error.id === id);

  return (
    <CustomModal
      heading={"Update Transaction"}
      open={isModalOpen}
      onClose={onClose}
      width={isLoading ? "650px" : "auto"}
      isFetching={isLoading}
      hideSubmitButton={
        currentImages === null || Object.keys(currentImages).length === 0
      }
      onSubmit={handleSubmit}
      showLoading={submitLoading}
    >
      <div className="flex-direction-column" >
          <div className={classes.cameraContainer} style={{height:"250px",marginTop:"-25px"}}>
              {currentImagesfirst && Object.keys(currentImagesfirst)
                ? Object.keys(currentImagesfirst).map((cameraNum) => (

                      <div className={classes.camera} key={cameraNum}  >
                      <img
                      style={{marginTop:"0px",height:"170px",width:"350px"}}
                        key={cameraNum}
                        src={currentImagesfirst[cameraNum].imageUrl}
                        alt={`Image ${cameraNum}`}
                      />
                    

                    <b> <span style={{marginLeft:"0px"}}>Camera : {(currentImagesfirst[cameraNum].imageName)}</span></b>
                    </div>        
                              
                  ))
                : "No data"
                }
            </div>

        <div className={classes.cameraContainer} style={{height:"100px"}}>
    {currentImages && Object.keys(currentImages)
      ? Object.keys(currentImages).map((cameraNum) => (

            <div className={classes.camera} key={cameraNum}  >
            <img
            style={{marginTop:"-30px",height:"170px",width:"350px"}}
              key={cameraNum}
              src={currentImages[cameraNum].imageUrl}
              alt={`Image ${cameraNum}`}
            />
           

           <b> <span style={{marginLeft:"0px"}}>Camera : {(currentImages[cameraNum].imageName)}</span></b>
          </div>        
                     
        ))
      : "No data"
      }
  </div>

        <div className={classes.addedItemContainer} style={{marginTop:"40px"}}>
          {addedItems.map((item, index) => (
            <div className="flex gap-10 align-items-center" key={item.id} style={{marginLeft:"170px",marginTop:"50px"}}>
              <Button
                aria-controls={`dropdown-menu-${index}`}
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, index)}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: "16px",
                  height: "54px",
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {item.selectedItem?.productName}
              </Button>
              <Menu
                id={`dropdown-menu-${index}`}
                anchorEl={
                  anchorEls && anchorEls.length > 0 ? anchorEls[index] : null
                }
                keepMounted
                open={Boolean(
                  anchorEls && anchorEls.length > 0 && anchorEls[index]
                )}
                onClose={() => handleMenuClose(index)}
              >
                {menuItems.map((menuItem, menuItemIndex) => {
                  return (
                    <MenuItem
                      key={menuItemIndex}
                      onClick={() => onChangeMenuItem(menuItem, item.id)}
                    >
                      {menuItem.productName}
                    </MenuItem>
                  );
                })}
              </Menu>
              <input
                className={`${classes.formInput} ${
                  isValidInput(item.id) ? "error-input-color" : ""
                }`}
                type={"number"}
                placeholder={"Enter Number of Quantities"}
                value={item.quantity}
                onChange={(e) => onChangeValue(e.target.value, item.id)}
              />
              {isValidInput(item.id) && (
                <span className="font-size-12">Must be greater than 0</span>
              )}
              {index > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteItem(item.id)}
                  style={{ height: "54px" }}
                >
                  Delete
                </Button>
              )}
            </div>
          ))}
        </div>

        <Button
          variant="contained"
          style={{
            color: "white",
            backgroundColor: "#FF5722",
            padding: "12px 24px",
            borderRadius: "10px",
            width: "150px",
            marginTop:"-65px"
          }}
          onClick={handleAddItem}
        >
          + Add Item
        </Button>
      </div>
      <Button
                  variant="contained"
                  style={{ backgroundColor: "#f50057",color:"white",marginLeft:"170px",marginBottom:"-90px",marginTop:"-28px",height:"45px"}}
                  onClick={handleNothing}
                >
                  Nothing Bought
                </Button>
    </CustomModal>
  );
};

export default UpdateModal;
