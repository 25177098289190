import React from "react";
import { makeStyles,Button } from "@material-ui/core";
import { useState } from 'react';

const customStyle =  makeStyles({
  root: {
    padding: "60px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
    formInput: {
      padding: "16px",
      border: "1px solid #ccc",
      borderRadius: "6px",
      fontSize: "18px",
      width: "250px",
      marginLeft:"25px"
    },
});

const Layout = (props) => {
  const {
  } = props;
  const classes = customStyle();

  // Inline CSS for the iframe container and the iframe itself

  const [searchQuery, setSearchQuery] = useState('');
  const [videos] = useState([
    { id: 1, title: 'How to add Pod', url: 'https://drive.google.com/file/d/18RR8yGUYhC9zfOAWM70bFuZV99ReB6pU/preview' },
    { id: 2, title: 'How to add Campus', url: 'https://drive.google.com/file/d/18RR8yGUYhC9zfOAWM70bFuZV99ReB6pU/preview' },
    { id: 3, title: 'How to add Vendor', url: 'https://drive.google.com/file/d/18RR8yGUYhC9zfOAWM70bFuZV99ReB6pU/preview' },
    { id: 4, title: 'How to use Carting Tool', url: 'https://drive.google.com/file/d/18RR8yGUYhC9zfOAWM70bFuZV99ReB6pU/preview' },
  ]);
  const [filteredVideos, setFilteredVideos] = useState(videos);

  // Function to handle the search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle the search button click
  const handleSearchClick = () => {
    const filtered = videos.filter((video) =>
      video.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredVideos(filtered);
  };

  return (
    <div className={classes.root} style={{marginTop:"-30px"}}>
     <div style={{marginTop:"-30px"}}>
     
     <input
        className={classes.formInput}
        type={"text"}
        placeholder={"Search videos..."}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    
      <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "#FF5722",
                padding: "12px 24px",
                borderRadius: "10px",
                marginLeft:"25px"
              }}
              onClick={handleSearchClick}
            >
              Search
            </Button>
      
      <div style={{display:"flex",justifyContent:"space-evenly",flexWrap:"wrap"}}>
        {filteredVideos.length > 0 ? (
          filteredVideos.map((video) => (
            <div key={video.id} style={{ margin: '20px 0' }}>
              <h5>{video.title}</h5>
              <iframe
                width="460"
                height="250"
                src={video.url}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sandbox="allow-same-origin allow-scripts"
              ></iframe>
            </div>
          ))
        ) : (
          <p>No videos found.</p>
        )}
      </div>
    </div>
    </div>
  );
};

export default Layout;
