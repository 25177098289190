export const routes = {
  HOME_PAGE: "/",
  ABOUT_PAGE: "/aboutPage",
  VENDOR_PAGE: "/VendorsPage",
  CAMPUS_PAGE: "/CampusPage",
  CONTACT_PAGE: "/contactPage",
  USERS: "/users",
  CAMPUS: "/campus",
  VENDOR: "/vendor",
  SIGN_UP: "/users/signup",
  USER_LOGIN: "/users/login",
  USER_TRANSACTION: "/users/userTransaction",
  USER_PROFILE:"/users/userInfo",
  WALLET: "/users/wallet",
  CAMPUS_LOGIN: "/campus/login",
  CAMPUS_PODS: "/campus/campusPods",
  VENDOR_LOGIN: "/vendor/login",
  VENDOR_PROFILE:"/vendor/vendorProfile",
  VENDOR_PODS: "/vendor/vendorPods",
  VENDOR_MANAGE_INVENTORY: "/vendor/manageInventory",
  DEFAULT: "",
  ADMIN: "/admin",
  ADMIN_PAGE:"/adminPage",
  ADMIN_LOGIN: "/admin/login",
  POD_MANAGEMENT: "/admin/podManagement",
  MANAGE_PODS: "/admin/podManagement/managePods",
  CAMPUS_MANAGEMENT: "/admin/campusManagement",
  CAMPUS_INFO: "/admin/campusManagement/campusInfo",
  VENDOR_MANAGEMENT: "/admin/vendorManagement",
  VENDOR_INFO: "/admin/vendorManagement/vendorInfo",
  INVENTORY_MANAGEMENT: "/admin/inventoryManagement",
  CARTING_TOOL: "/admin/cartingTool",
  USER_MANAGEMENT: "/admin/userManagement",
  USER_INFO: "/admin/userManagement/userInfo",
  VENDOR_MANAGE_PRODUCTS:"/vendor/manageProducts",
  TUTORIALS_ADMIN:"/admin/tutorials"
};
