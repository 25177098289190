import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, makeStyles } from "@material-ui/core";
import { CircularLoader } from "../widgets/CircularLoader";

const useStyles = (width) =>
  makeStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      outline: "none",
      position: "absolute",
      width: width,
      backgroundColor: "white",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
      padding: 14,
      minWidth: "200px",
      minHeight: "250px",
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #ccc",
      marginBottom: "30px",
      paddingBottom: "10px",
    },
    madalHeading: {
      margin: "0px",
      fontSize: "20px",
    },
    closeButton: {
      cursor: "pointer",
      marginLeft: "auto",
    },
    actionBtn: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "25px",
    },
  });

const CustomModal = (props) => {
  const {
    open,
    onClose,
    children,
    onSubmit,
    heading,
    showLoading,
    isFetching,
    width = "650px",
    hideSubmitButton = false,
  } = props;
  const classes = useStyles(width)();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Paper className={classes.paper}>
        {isFetching ? (
          // height is given 200px because the min-height for no data is set 200px in paper
          <CircularLoader height="200px" />
        ) : (
          <>
            <div className="flex-grow-1">
              <div className={classes.modalHeader}>
                <h2 className={classes.madalHeading}>{heading}</h2>
                <CloseIcon className={classes.closeButton} onClick={onClose} />
              </div>
              {showLoading && <CircularLoader isPoistionAbsolute={true} />}
              {children}
            </div>
            <div className={classes.actionBtn}>
              {!hideSubmitButton && (
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#00C853", width: "150px",marginTop:"-25px",marginBottom:"20px"}}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              )}
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #D32F2F",
                  color: "black",
                  width: "150px",
                  marginLeft: "auto",
                  
                }}
                onClick={onClose}
              >
                Close
              </Button>
            </div>
          </>
        )}
      </Paper>
    </Modal>
  );
};

export default CustomModal;
