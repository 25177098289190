import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { CustomTable, SearchTableInput } from "../../../components";
import { useNavigate } from "react-router-dom";
import { podTableHeading } from "../../../assets/lib/pod";
import { useState } from "react";
import moment from "moment";

const customStyle = makeStyles({
  root: {
    padding: "20px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  buttonContainer: {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionBtn: {
    display: "flex",
    gap: "20px",
  },
  dash: {
    border: "0.5px solid #000",
    backgroundColor: "black",
    height: "1px",
    width: "10px",
  },
  datePicker: {
    width: "150px",
  },
});

const Layout = (props) => {
  const {
    podTableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    openModal,
    fetchPodNumber,
    fetchInventoryData
  } = props;
  const classes = customStyle();
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());

  const handleViewClick = (row) => {
    // Pass the podNumber to the openModal function
    // console.log("layout",row.podNumber);
    openModal(row.podNumber);

    // Fetch the podNumber
    fetchInventoryData(row.podNumber);
  };


  const actions = [
    {
      label: "View",
      // onClick: (row) => {
      //   // navigate(`${routes.CAMPUS_INFO}/${row.id}`, {
      //   //   state: { addCampus: false, editCampus: false },
      //   // });
      // },
      onClick:handleViewClick,
      bgColor: "#2196F3",
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <SearchTableInput
          tableHeading={podTableHeading}
          handleSearchQuery={handleSearchQuery}
          width={"200px"}
        />
        <div className={classes.actionBtn}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="From Date"
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
              className={classes.datePicker}
            />
            <span className={classes.dash} />
            <DatePicker
              label="To Date"
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
              className={classes.datePicker}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#FF5722",
              padding: "12px 24px",
              borderRadius: "10px",
            }}
            onClick={() => {}}
          >
            Search
          </Button>
        </div>
      </div>
      <CustomTable
        columns={podTableHeading}
        data={podTableData}
        actions={actions}
        isLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleTableSorting={handleTableSorting}
      />
    </div>
  );
};

export default Layout;
