import React from "react";
import { makeStyles } from "@material-ui/core";
import Header from "./Header";
import About from "./About";
import Product from "./Product";

const customStyles = makeStyles({
  root: {
    transition:
      "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
    marginTop: "0px",
    padding: "140px 0px 150px 0px",
    position: "relative",
    zIndex: "1",
  },
  videoWrapper: {
    height: "900px", // 1029px
    width: "100%",
    top: "0",
    left: "0",
    position: "absolute",
    overflow: "hidden",
    zIndex: "0",
    direction: "ltr",
  },
  videoBG: {
    objectFit: "cover",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    maxWidth: "100%",
    width: "1830px",
    height: "900px", // 1029px
    margin: "0",
    lineHeight: "1",
    border: "none",
    pointerEvents: "none",
  },
  whiteBg: {
    backgroundColor: "transparent",
    backgroundImage: "linear-gradient(110deg,#FFFFFF  40%, #FFFFFF00 100%)",
    opacity: "1",
    transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
    height: "100%",
    width: "100%",
    top: "0",
    left: "0",
    position: "absolute",
  },
});
const Layout = () => {
  const classes = customStyles();

  return (
    <div className={classes.root}>
      <div className={classes.videoWrapper}>
        <video
          autoPlay
          muted={true}
          playsInline={true}
          loop={true}
          className={classes.videoBG}
          src="https://finflow.uicore.co/financial-planning/wp-content/uploads/sites/7/2023/03/financial-planning-video.mp4"
          //   style={{width: 1823.38px height: 1025.65px}}
        />
        <div className={classes.whiteBg} />
      </div>
      <Header />
      <About />
      <Product />
    </div>
  );
};

export default Layout;
