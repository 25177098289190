const vendorData = {
  vendorId: {
    key: "vendorId",
    label: "Vendor ID",
    placeHolder: "Enter Vendor Id",
    type: "text",
  },
  vendorName: {
    key: "vendorName",
    label: "Vendor Name",
    placeHolder: "Enter Vendor name",
    type: "text",
    isRequired: true,
  },
  GSTNumber: {
    key: "GSTNumber",
    label: "GST Number",
    placeHolder: "Enter GST Number",
    type: "text",
    isRequired: true,
  },
  phoneNumber: {
    key: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Enter Phone number",
    type: "number",
    isRequired: true,
  },
  vendorAddress: {
    key: "vendorAddress",
    label: "Address",
    placeHolder: "Enter Vendor address",
    type: "text",
  },
  location: {
    key: "location",
    label: "Location",
    placeHolder: "Enter Google Map Location",
    type: "text",
  },
  zone: {
    key: "zone",
    label: "Zone",
    placeHolder: "Enter Zone",
    type: "text",
  },
  rent: {
    key: "rent",
    label: "Rent",
    placeHolder: "Enter Rent",
    type: "number",
  },
  noOfPods: {
    key: "noOfPods",
    label: "Number Of Pods",
    placeHolder: "Enter Number Of Pods",
    type: "number",
    defaultValue: 0,
  },
  commission: {
    key: "commission",
    label: "Commission %",
    placeHolder: "Enter Commission percentage",
    type: "number",
  },
  contactPersonName: {
    key: "contactPersonName",
    label: "Name",
    placeHolder: "Enter name",
    type: "text",
  },
  contactPersonPhone: {
    key: "contactPersonPhone",
    label: "Phone Number",
    placeHolder: "Enter Phone Number",
    type: "number",
  },
};

const vendorTableHeading = [
  {
    ...vendorData.vendorId,
    sorting: true,
    styleHeader: {
      minWidth: "105px",
    },
  },
  {
    ...vendorData.vendorName,
    styleHeader: {
      minWidth: "107px",
    },
  },
  { ...vendorData.location },
  { ...vendorData.zone },
  { ...vendorData.vendorAddress },
  {
    ...vendorData.noOfPods,
    sorting: true,
    styleHeader: {
      minWidth: "148px",
    },
  },
  {
    ...vendorData.rent,
    sorting: true,
  },
  {
    key: "document",
    label: "Document",
  },
];

const vendorManageInventory = [
  {
    key: "count",
    label: "Count",
  },
  {
    key: "itemName",
    label: "Item Name",
  },
  {
    key: "unitprice",
    label: "Price",
  },
];

const inventoryPodData = {
  itemName: {
    key: "itemName",
    label: "Item Name",
    placeHolder: "Item Name",
    type: "text",
  },
  unitprice: {
    key: "unitprice",
    label: "Unit Price",
    placeHolder: "Enter Unit Price",
    type: "text",
    // isRequired: true,
  },
  count: {
    key: "count",
    label: "count",
    placeHolder: "Enter count",
    type: "text",
  },
};



export {
  vendorData,
  vendorTableHeading,
  vendorManageInventory,
  inventoryPodData,
};
