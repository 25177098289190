import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";

const customStyles = makeStyles({
  headerWrapper: {
    width: "90%",
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
    position: "relative",
    flexWrap: "wrap",
    alignContent: "flex-start",
    flexDirection: "column",
    fontFamily: "Manrope",  
  },
  headerContainer: {
    textAlign: "left",
    maxWidth: "700px",
  },
  header: {
    margin: "0px",
    fontWeight: "600",
    letterSpacing: "-0.027em",
    lineHeight: "1.1",
    textTransform: "none",
    color: "#271340",
    fontSize: "64px",
    fontWeight: "600", 
  },
  headerBodyContainer: {
    marginTop: "20px",
    maxWidth: "520px",
    color: "#271340",
    fontSize: "20px",
    fontWeight: "300",
  },
  headerBtnWrapper: {
    display: "flex",
    marginTop: "76px",
    gap: "16px",
  },
  divider: {
    marginTop: "32px",
    color: "#80808047",
    borderTop: "1px solid #80808047",
    width: "346px",
  },
  headerUsers: {
    marginTop: "32px",
    fontWeight: "400",
    display: "flex",
    width: "330px",
    fontSize: "18px",
    alignItems: "center",
    gap: "10px",
  },
});

const Header = () => {
  const classes = customStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerContainer}>
        <h1 className={classes.header}>
          First-of-its-kind 24*7 retail platform for your community
        </h1>
      </div>
      <div className={classes.headerBodyContainer}>
        Snacks, stationary and books in 3 simple steps, right next to your desk.
      </div>
      <div className={classes.headerBtnWrapper}>
        <div
          className={"purpleBtn"}
          onClick={() => {
            navigate(routes.USER_LOGIN);
          }}
        >
          Login <ArrowForwardIcon />
        </div>
        <div
          className={"purpleBtn"}
          onClick={() => {
            navigate(routes.SIGN_UP);
          }}
        >
          Create your secret alpin <ArrowForwardIcon />
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.headerUsers}>
        <h1 className={classes.header}>1K+</h1>
        <span>Active users around the world</span>
      </div>
    </div>
  );
};

export default Header;
