import { Timestamp } from "firebase/firestore";
import { checkValidMoment } from "../../common/common";

const transactionTableHeading = [
  {
    key: "id",
    label: "Transaction ID",
  },
  {
    key: "transactionTime",
    label: "Date",
    format: (value) => checkValidMoment(value?.toDate(), "YYYY-MM-DD hh:mm A"),
  },
  {
    key: "campusName",
    label: "Campus Name",
  },
  {
    key: "machineId",
    label: "Pod Number",
  },
  {
    key: "itemsPicked",
    label: "Items Picked",
  },
  {
    key: "transactionAmount",
    label: "Transaction Amount",
  },
  {
    key: "cartingStatus",
    label: "Carting Status",
  },
];

export { transactionTableHeading };
