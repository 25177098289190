import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { Button, TablePagination } from "@mui/material";
import { CircularLoader } from "../widgets/CircularLoader";
import SortIcon from "@mui/icons-material/Sort";

const CustomTable = (props) => {
  const {
    columns,
    data,
    actions = [],
    rowsPerPageOptions = [5, 10, 15],
    actionsHeader = "Actions",
    isLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleTableSorting,
  } = props;
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [maxTableHeight, setMaxTableHeight] = useState("500px");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Calculate the max table height based on viewport height
  useEffect(() => {
    const calculateMaxTableHeight = () => {
      const viewportHeight = window.innerHeight;
      // You can adjust the offset as needed
      const offset = 220; // Adjust this value as needed
      setMaxTableHeight(`calc(100vh - ${offset}px)`);
    };

    calculateMaxTableHeight();

    // Add an event listener to recalculate max table height on window resize
    window.addEventListener("resize", calculateMaxTableHeight);

    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value);
    setRowsPerPage(pageSize);
    handlePageSize(pageSize);
  };

  const handleSort = (columnKey) => {
    setSortConfig((prevSortConfig) => {
      let newSortConfig;

      if (prevSortConfig.key === columnKey) {
        newSortConfig = {
          key: columnKey,
          direction: prevSortConfig.direction === "asc" ? "desc" : null,
        };
      } else {
        newSortConfig = {
          key: columnKey,
          direction: "asc",
        };
      }
      if (newSortConfig.direction === null) {
        newSortConfig.key = null;
      }
      handleTableSorting(newSortConfig.key, newSortConfig.direction);

      return newSortConfig;
    });
  };

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: maxTableHeight, maxWidth: "100%" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{ backgroundColor: "#b2cdf3", ...column.styleHeader }}
                >
                  {column?.sorting ? (
                    <TableSortLabel
                      active={true}
                      direction={
                        sortConfig.key === column.key
                          ? sortConfig.direction
                          : "desc"
                      }
                      onClick={() => handleSort(column.key)}
                      IconComponent={
                        sortConfig.key === column.key
                          ? sortConfig.direction === "asc"
                            ? undefined // Use the default arrow icon
                            : undefined // Use the default arrow icon
                          : SortIcon // Insert your custom icon component here
                      }
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
              {actions && actions.length !== 0 && (
                <TableCell
                  key={"action"}
                  style={{ backgroundColor: "#b2cdf3" }}
                >
                  {actionsHeader}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((row, index) => (
                <TableRow
                  key={index}
                  className={index % 2 === 0 ? "" : "bg-gray"}
                >
                  {columns.map((column, index) => (
                    <TableCell
                      className="font-family-project"
                      key={index}
                      style={{
                        ...column.styleItem,
                      }}
                    >
                      {column.format
                        ? column.format(row[column.key])
                        : row[column.key]}
                    </TableCell>
                  ))}
                  {actions && actions.length !== 0 && (
                    <TableCell>
                      <div className="flex gap-10">
                        {actions.map((action, index) => (
                          <Button
                            // className={classes.viewButton}
                            variant="contained"
                            onClick={() => action.onClick(row)}
                            style={{ backgroundColor: action.bgColor }}
                            key={index}
                          >
                            {action.label}
                          </Button>
                        ))}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalLength}
        page={currentPage}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};

export default CustomTable;
