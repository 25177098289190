import KitchenIcon from "@mui/icons-material/Kitchen";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { routes } from "../routing/routes";

export const AdminNavTabs = [
  {
    id: "podManagement",
    label: "Pod Management",
    icon: <KitchenIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.POD_MANAGEMENT,
  },
  {
    id: "campusManagement",
    label: "Campus Management",
    icon: <LocationCityIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.CAMPUS_MANAGEMENT,
  },
  {
    id: "vendorManagement",
    label: "Vendor Management",
    icon: <StorefrontIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.VENDOR_MANAGEMENT,
  },
  {
    id: "inventoryManagement",
    label: "Inventory Management",
    icon: <InventoryIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.INVENTORY_MANAGEMENT,
  },
  {
    id: "cartingTool",
    label: "Carting Tool",
    icon: <ShoppingCartIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.CARTING_TOOL,
  },
  {
    id: "userManagement",
    label: "User Management",
    icon: <AccountBoxIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.USER_MANAGEMENT,
  },
  {
    id: "tutorial Lounge",
    label: "Tutorial Lounge",
    icon: <LibraryBooksIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.TUTORIALS_ADMIN,
  },
];
